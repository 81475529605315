import React from 'react'
import {Card, CardDeck, Button} from 'react-bootstrap'
import chessAnalysis from '../assets/ios_full.png'
import queensGambit from '../assets/QGthumbnail.png'
import PLD from '../assets/PLD.png'
import covidTracker from '../assets/covidTracker.png'
import {Link} from 'react-router-dom'

const Projects = () => {
    return (
        <div className='d-flex ' style={{paddingTop:30, flexDirection:'row', flexWrap:'wrap',alignContent:'space-between'
                                        ,justifySelf:'center' }}>
           
              <Card className='mx-3 my-3' style={{ minWidth: '15rem', maxWidth: '20rem'}}>
            <a href=''><Card.Img variant="top" src={PLD} /></a>
            <Card.Body>
              <Card.Title className='font-weight-bold'>Panopto Lecture Downloader</Card.Title>
              <Card.Text>
                Developed a chrome extension to help college students download past lecture videos for educational purposes. Includes unprecedented support for multi stream.
              </Card.Text>
              <Button variant="success" href=''>Coming Soon</Button>
            </Card.Body>
          </Card>
            <Card className='mx-3 my-3' style={{  minWidth: '15rem', maxWidth: '20rem' }}>
          <a href='https://drive.google.com/file/d/1-2RPdYzlpbQX3l15bhqqC-y1zMFD5Gvo/view?usp=sharing'><Card.Img variant="top" src={queensGambit} /></a>
          <Card.Body>
            <Card.Title className='font-weight-bold'>Chess.com Adoption Analysis</Card.Title>
            <Card.Text>
              Designed a 2-segment Weibull model with covariates to capture the Chess.com user growth as a result of the netflix show and others.
            </Card.Text>
            <Button variant="success" href='https://drive.google.com/file/d/1-2RPdYzlpbQX3l15bhqqC-y1zMFD5Gvo/view?usp=sharing'>Read</Button>
          </Card.Body>
        </Card>
        <Card className='mx-3 my-3' style={{ minWidth: '15rem', maxWidth: '20rem' }}>
          <a href='https://covid-vac-tracker.azurewebsites.net/'><Card.Img variant="top" src={covidTracker} /></a>
          <Card.Body>
            <Card.Title className='font-weight-bold'>Covid-Vac-Tracker</Card.Title>
            <Card.Text>
              Daily updated global covid vaccine data. Built with React, Puppeteer, and Anti-Chinese-Government-Anti-Bot technology.
            </Card.Text>
            <Button variant="success" href='https://covid-vac-tracker.azurewebsites.net/'>Check It Out</Button>
          </Card.Body>
        </Card>
        
        </div>
    )
}

export default Projects
