import logo from './logo.svg';
import profile from './assets/profile.gif';
import profile_sm from './assets/profile_sm.gif';
import profile_xs from './assets/profile_xs.gif';
import Projects from './components/Projects'
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import {Container, Row, Col,Image, Button, Card, CardDeck} from "react-bootstrap"
//import styled, { keyframes } from 'styled-components';
//import { fadeInDown,bounce } from 'react-animations';
import {useState, useEffect, useCallback} from 'react'
import TextLoop from "react-text-loop";




function App() {





  return (
    <main className="container" style={{paddingTop:'5rem ',flexDirection:'column',justifyContent:'center',alignContent:'center',maxWidth:'800px' }}>
      
      <div className=" " style={{display:'flex',justifyContent:'space-between',alignSelf:'center',flexDirection:'row'}} >
          <div className="p-1" >
            <img src={profile_xs} alt='' style={{height:'auto',maxWidth:'100%'}}/>
          </div>
          
          <div className="d-flex">
            <div className='align-self-center'>
              <h1 className='font-weight-bold' style={{textAlign:'right'}}>Hey! I'm Yiheng</h1>
              
            </div>
          </div>
      </div>
      <div style={{paddingBottom:50}}>
      <h2 style={{textAlign:'right', fontSize:'medium'}}>
                I'm 
              
              </h2>
              <h2 style={{textAlign:'right', fontSize:'large'}}>
              <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    
                    <span>studying CS and Business @ UPenn</span>
                    <span> reading Pal's case</span>
                    <span> looking for a job</span>
                    
              </TextLoop>{" "}
              </h2>
      </div>
      <div className=''>
        <p style={{textAlign:'left',lineHeight:1.5, fontSize:'1.5rem'}}>
          <strong className=''>Join my network:</strong>
          <a className='' href='https://www.linkedin.com/in/yiheng-intel-chen-98115986/' target='_blank' rel="noreferrer"> LinkedIn </a>,
          <a className='' href='https://github.com/intelc/' target='_blank' rel="noreferrer"> Github </a>,
          <a className='' href='https://www.instagram.com/yic.p/' target='_blank' rel="noreferrer"> Insta </a>
        </p>
        <p style={{textAlign:'left',lineHeight:1.5, fontSize:'1.5rem'}}>
          <strong className=''>The short blurb: </strong>
          I am a photographer turned roboticist turned CS student turned data scientist. Still trying to figure out how the world works.
        </p>
      </div>
      <div style={{paddingTop:30}}>
        <h2 className='font-weight-bold'> My Projects</h2>
        <Projects/>
      </div>

       

      
      
            
        
    </main>
  );
}

export default App;
